import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DataContext } from "../DataContext";
import Carousel from "./Carousel";
import TopicCarousel from "./TopicCarousel";
import { fetchEpisodes, fetchSeries, fetchSearch } from "./Fetching"; // Ensure these functions are defined in your Fetching file
import { pushGTMEvent } from "../GoogleAnalytics";

const Explore = ({ handlePlay, handlePause, setSearchQuery, getToken }) => {
  const { t } = useTranslation();
  const { query } = useParams();
  const { episodes, series, loadContent, isLoadingContent } =
    useContext(DataContext);
  const [searchResults, setSearchResults] = useState({
    episodes: [],
    series: [],
  });
  const [audiobooks, setAudiobooks] = useState([]);
  const [audioguides, setAudioguides] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);
  const initialLoad = useRef(true);

  console.log("Loading Content loaded:", contentLoading);
  pushGTMEvent("page_view", "Page Interaction", window.location);

  const fetchContentDataContext = async () => {
    console.log("Inside fetchContentDataContext function");
    if (contentLoading) {
      return;
    }
    await setContentLoading(true);
    try {
      await loadContent(getToken);
    } catch (error) {
      console.error("Error loading content:", error);
    } finally {
      initialLoad.current = false;
    }
  };

  // Load content without query from context
  useEffect(() => {
    console.log("Inside useEffect for content loading check");
    if (
      initialLoad.current &&
      !query &&
      !contentLoading &&
      !isLoadingContent &&
      !episodes.length &&
      !series.length
    ) {
      console.log("Loading content from context,", isLoadingContent);
      fetchContentDataContext();
    }
  }, [query, isLoadingContent, episodes.length, series.length]);

  // Handle search query
  useEffect(() => {
    if (query) {
      console.log("Search query:", query);
      const performSearch = async () => {
        const token = await getToken();
        fetchSearch(query, token)
          .then((data) => {
            setSearchResults({
              episodes: data.episodes.filter((episode) => episode.score > 0),
              series: data.series.filter((serie) => serie.score > 0),
            });
          })
          .catch((error) => {
            console.error("Error during search:", error);
          });
      };
      performSearch();
    }
  }, [query, getToken]);

  return (
    <div className="content-package" id="library">
      {(searchResults.series.length !== 0 ||
        searchResults.episodes.length !== 0) &&
        query && <h2>{t("searchResultsFor", { query })}</h2>}

      {searchResults.series.length === 0 &&
        searchResults.episodes.length === 0 &&
        query && <h2>{t("noResultsFoundFor", { query })}</h2>}

      {!query && <TopicCarousel />}

      {query ? (
        <>
          {searchResults.series.length !== 0 && (
            <Carousel
              title={t("series")}
              handlePlay={handlePlay}
              inCommingElements={searchResults.series}
              handleClick={() => {
                console.log("Series clicked");
              }}
              loading={!searchResults.series.length}
            />
          )}
          {searchResults.episodes.length !== 0 && (
            <Carousel
              title={t("episodes")}
              handlePlay={handlePlay}
              inCommingElements={searchResults.episodes}
              handleClick={() => {
                console.log("Episode clicked");
              }}
              loading={!searchResults.episodes.length}
            />
          )}
        </>
      ) : (
        <>
          {audiobooks.length ? (
            <Carousel
              title={t("audiobooks")}
              handlePlay={handlePlay}
              inCommingElements={audiobooks}
              handleClick={() => {
                console.log("Audiobook clicked");
              }}
              loading={!audiobooks.length}
            />
          ) : null}
          {audioguides.length ? (
            <Carousel
              title={t("audioguides")}
              handlePlay={handlePlay}
              inCommingElements={audioguides}
              handleClick={() => {
                console.log("Audioguide clicked");
              }}
              loading={!audioguides.length}
            />
          ) : null}

          <Carousel
            title={t("series")}
            handlePlay={handlePlay}
            inCommingElements={series}
            handleClick={() => {
              console.log("Series clicked");
            }}
            loading={!series.length}
          />
          <Carousel
            title={t("episodes")}
            handlePlay={handlePlay}
            inCommingElements={episodes}
            handleClick={() => {
              console.log("Episode clicked");
            }}
            loading={!episodes.length}
          />
        </>
      )}
    </div>
  );
};

export default Explore;
