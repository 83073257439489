import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause, faCrown } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select"; // Import react-select for the searchable dropdown

const VoiceSelector = ({
	voices,
	userSubscription,
	selectedVoice,
	onVoiceChange,
}) => {
	const [playingVoice, setPlayingVoice] = useState(null);
	const audioRef = useRef(null);

	// Handle voice selection change and notify parent
	const handleVoiceChange = (selectedOption) => {
		onVoiceChange(selectedOption.value);
	};

	const handleVoicePreview = (voice) => {
		if (playingVoice === voice.id) {
			audioRef.current.pause();
			setPlayingVoice(null);
		} else {
			if (voice.preview_url) {
				audioRef.current.src = voice.preview_url;
				audioRef.current.play();
				setPlayingVoice(voice.id);
			} else {
				alert("No preview available for this voice yet.");
			}
		}
	};

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.onended = () => setPlayingVoice(null);
		}
	}, []);

	// Sort voices by premium status (non-premium first) and then alphabetically
	const sortedVoices = voices.sort((a, b) => {
		// Sort by premium status first
		if (a.premium && !b.premium) return 1;
		if (!a.premium && b.premium) return -1;

		// Then sort by name alphabetically
		return a.name.localeCompare(b.name);
	});

	// Helper function to filter out empty or undefined fields
	const formatLabels = (labels) => {
		const { accent, age, gender, usecase } = labels;
		const labelParts = [accent, age, gender, usecase].filter(
			(part) => part && part !== "undefined" && part !== ""
		);
		return labelParts.join(", ");
	};

	// Map voices to react-select format, adding the premium icon if necessary
	const voiceOptions = sortedVoices.map((v) => ({
		value: v.id,
		label: (
			<div style={{ display: "flex", alignItems: "center" }}>
				<span>{v.name}</span>
				{v.premium && (
					<FontAwesomeIcon
						icon={faCrown}
						style={{ color: "gold", marginLeft: "5px" }}
					/>
				)}
				<span style={{ marginLeft: "10px" }}>
					{formatLabels(v.labels)}
				</span>
			</div>
		),
	}));

	return (
		<div style={{ display: "flex", alignItems: "center", width: "70%" }}>
			<Select
				value={voiceOptions.find((option) => option.value === selectedVoice)}
				onChange={handleVoiceChange}
				options={voiceOptions}
				styles={{ container: (provided) => ({ ...provided, width: "90%" }) }}
				isSearchable={true} // Enable searching
			/>
			<button
				style={{ marginRight: "10px", width: "10%" }}
				onClick={() =>
					handleVoicePreview(
						sortedVoices.find((v) => v.id === selectedVoice)
					)
				}
			>
				<FontAwesomeIcon
					icon={playingVoice === selectedVoice ? faPause : faPlay}
				/>
			</button>
			<audio ref={audioRef} />
		</div>
	);
};

export default VoiceSelector;
