const voices = [
	{
		_id: "660594db5f3cc96b0218af26",
		name: "Bethany",
		category: "premade",
		labels: {
			accent: "american",
			age: "middle aged",
			gender: "female",
			usecase: "scientific",
		},
		description: "",
		preview_url:
			"https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
		settings: null,
		id: "alloy",
		premium: false,
	},
	{
		_id: "660594db5f3cc96b0218af28",
		name: "Thomas",
		category: "premade",
		labels: {
			accent: "english",
			age: "middle aged",
			gender: "male",
			usecase: "podcast",
		},
		description: "",
		preview_url:
			"https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
		settings: null,
		id: "fable",
		premium: false,
	},
	{
		_id: "660594db5f3cc96b0218af29",
		name: "Leo",
		category: "premade",
		labels: {
			accent: "english",
			age: "middle aged",
			gender: "male",
			usecase: "audioguide",
		},
		description: "",
		preview_url:
			"https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
		settings: null,
		id: "echo",
		premium: false,
	},
	{
		_id: "660594db5f3cc96b0218af2a",
		name: "Michael",
		category: "premade",
		labels: {
			accent: "american",
			age: "mature",
			gender: "male",
			usecase: "audioguide",
		},
		description: "",
		preview_url:
			"https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
		settings: null,
		id: "onyx",
		premium: false,
	},
	{
		_id: "660594db5f3cc96b0218af2b",
		name: "Carmen",
		category: "premade",
		labels: {
			accent: "american",
			age: "middle aged",
			gender: "female",
			usecase: "audiobook",
		},
		description: "",
		preview_url:
			"https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
		settings: null,
		id: "nova",
		premium: false,
	},
	{
		_id: "660594db5f3cc96b0218af2c",
		name: "Jessy",
		category: "premade",
		labels: {
			accent: "american",
			age: "young",
			gender: "female",
			usecase: "news",
		},
		description: "",
		preview_url:
			"https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
		settings: null,
		id: "shimmer",
		premium: false,
	},
	{
		_id: "653286775739d1426af46b2a",
		name: "Varvara",
		category: "cloned",
		labels: {
			usecase: "news",
			age: "young",
			accent: "neutral",
			gender: "female",
		},
		description: "varavara's voice",
		preview_url:
			"https://storage.googleapis.com/eleven-public-prod/AEZexRTceyPIfr5KZquDT4uHlus1/voices/MZUEqzjJhpmguYXgf5oY/b7cc14da-2984-4f39-87ae-041518a488d9.mp3",
		settings: null,
		id: "MZUEqzjJhpmguYXgf5oY",
		premium: true,
	},

		{
			"id": "21m00Tcm4TlvDq8ikWAM",
			"name": "Emily",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "american",
				"description": "calm",
				"age": "young",
				"gender": "female",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/b4928a68-c03b-411f-8533-3d5c299fd451.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": []
			
		},
		{
			"id": "29vD33N1CtxCmqQRPOHJ",
			"name": "James",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "well-rounded",
				"age": "middle-aged",
				"gender": "male",
				"use_case": "news"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/29vD33N1CtxCmqQRPOHJ/b99fc51d-12d3-4312-b480-a8a45a7d51ef.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "2EiwWnXFnvU5JabPnv8n",
			"name": "Martin",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "war veteran",
				"age": "middle-aged",
				"gender": "male",
				"use_case": "characters"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/2EiwWnXFnvU5JabPnv8n/65d80f52-703f-4cae-a91d-75d4e200ed02.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "5Q0t7uMcjvnagumLfvZi",
			"name": "David",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "authoritative",
				"age": "middle-aged",
				"gender": "male",
				"use_case": "news"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/5Q0t7uMcjvnagumLfvZi/a4aaa30e-54c4-44a4-8e46-b9b00505d963.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "AZnzlk1XvdvUeBnXmlld",
			"name": "Sophia",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "american",
				"description": "strong",
				"age": "young",
				"gender": "female",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/AZnzlk1XvdvUeBnXmlld/b3c36b01-f80d-4b16-a698-f83682dee84c.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": []
			
		},
		{
			"id": "CYw3kZ02Hs0563khs1Fj",
			"name": "Oliver",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "British",
				"description": "conversational",
				"age": "young",
				"gender": "male",
				"use_case": "characters"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/CYw3kZ02Hs0563khs1Fj/872cb056-45d3-419e-b5c6-de2b387a93a0.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "D38z5RcWu1voky8WS1ja",
			"name": "Liam",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "Irish",
				"description": "sailor",
				"age": "old",
				"gender": "male",
				"use_case": "characters"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/D38z5RcWu1voky8WS1ja/a470ba64-1e72-46d9-ba9d-030c4155e2d2.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "EXAVITQu4vr4xnSDxMaL",
			"name": "Grace",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "american",
				"description": "soft",
				"age": "young",
				"gender": "female",
				"use_case": "news"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/EXAVITQu4vr4xnSDxMaL/01a3e33c-6e99-4ee7-8543-ff2216a32186.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "ErXwobaYiN019PkySvjV",
			"name": "Alexander",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "american",
				"description": "well-rounded",
				"age": "young",
				"gender": "male",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/ErXwobaYiN019PkySvjV/2d5ab2a3-4578-470f-b797-6331e46a7d55.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": []
			
		},
		{
			"id": "FGY2WhTYpPnrIDTdsKH5",
			"name": "Mia",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "upbeat",
				"age": "young",
				"gender": "female",
				"use_case": "social media"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/FGY2WhTYpPnrIDTdsKH5/67341759-ad08-41a5-be6e-de12fe448618.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "GBv7mTt0atIp3Br8iCZE",
			"name": "Ethan",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "calm",
				"age": "young",
				"gender": "male",
				"use_case": "meditation"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/GBv7mTt0atIp3Br8iCZE/98542988-5267-4148-9a9e-baa8c4f14644.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "IKne3meq5aSn9XLyUdCD",
			"name": "Jack",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "Australian",
				"description": "natural",
				"age": "middle aged",
				"gender": "male",
				"use_case": "conversational"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/IKne3meq5aSn9XLyUdCD/102de6f2-22ed-43e0-a1f1-111fa75c5481.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "JBFqnCBsd6RMkjVDRZzb",
			"name": "William",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "British",
				"description": "warm",
				"age": "middle aged",
				"gender": "male",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/JBFqnCBsd6RMkjVDRZzb/e6206d1a-0721-4787-aafb-06a6e705cac5.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "LcfcDJNUP1GQjkzn1xUU",
			"name": "Hannah",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "calm",
				"age": "young",
				"gender": "female",
				"use_case": "meditation"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/LcfcDJNUP1GQjkzn1xUU/e4b994b7-9713-4238-84f3-add8fccaaccd.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "MF3mGyEYCl7XYWbV9V6O",
			"name": "Ava",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "american",
				"description": "emotional",
				"age": "young",
				"gender": "female",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/MF3mGyEYCl7XYWbV9V6O/d8ecadea-9e48-4e5d-868a-2ec3d7397861.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": []
			
		},
		{
			"id": "N2lVS1w4EtoT3dr4eOWO",
			"name": "Lucas",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "Transatlantic",
				"description": "intense",
				"age": "middle-aged",
				"gender": "male",
				"use_case": "characters"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/N2lVS1w4EtoT3dr4eOWO/ac833bd8-ffda-4938-9ebc-b0f99ca25481.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "ODq5zmih8GrVes37Dizd",
			"name": "Daniel",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "shouty",
				"age": "middle-aged",
				"gender": "male",
				"use_case": "characters"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/ODq5zmih8GrVes37Dizd/0ebec87a-2569-4976-9ea5-0170854411a9.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "SOYHLrjzK2X1ezoPC6cr",
			"name": "Noah",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "anxious",
				"age": "young",
				"gender": "male",
				"use_case": "characters"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/SOYHLrjzK2X1ezoPC6cr/86d178f6-f4b6-4e0e-85be-3de19f490794.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "TX3LPaxmHKxFdv7VOQHJ",
			"name": "Jacob",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "articulate",
				"age": "young",
				"gender": "male",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TX3LPaxmHKxFdv7VOQHJ/63148076-6363-42db-aea8-31424308b92c.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "ThT5KcBeYPX3keUQqHPh",
			"name": "Charlotte",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "British",
				"description": "pleasant",
				"age": "young",
				"gender": "female",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/ThT5KcBeYPX3keUQqHPh/981f0855-6598-48d2-9f8f-b6d92fbbe3fc.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "TxGEqnHWrfWFTfGW9XjX",
			"name": "Ryan",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "american",
				"description": "deep",
				"age": "young",
				"gender": "male",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/47de9a7e-773a-42a8-b410-4aa90c581216.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": []
			
		},
		{
			"id": "VR6AewLTigWG4xSOukaG",
			"name": "Henry",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "american",
				"description": "crisp",
				"age": "middle aged",
				"gender": "male",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/VR6AewLTigWG4xSOukaG/49a22885-80d5-48e8-87a3-076fc9193d9a.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": []
			
		},
		{
			"id": "XB0fDUnXU5powFXDhCwa",
			"name": "Isabella",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "Swedish",
				"description": "seductive",
				"age": "young",
				"gender": "female",
				"use_case": "characters"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/XB0fDUnXU5powFXDhCwa/942356dc-f10d-4d89-bda5-4f8505ee038b.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "Xb7hH8MSUJpSbSDYk0k2",
			"name": "Amelia",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "British",
				"description": "confident",
				"age": "middle-aged",
				"gender": "female",
				"use_case": "news"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/Xb7hH8MSUJpSbSDYk0k2/d10f7534-11f6-41fe-a012-2de1e482d336.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "XrExE9yKIg1WjnnlVkGX",
			"name": "Victoria",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "friendly",
				"age": "middle-aged",
				"gender": "female",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/XrExE9yKIg1WjnnlVkGX/b930e18d-6b4d-466e-bab2-0ae97c6d8535.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "ZQe5CZNOzWyzPSCn5a3c",
			"name": "Samuel",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "Australian",
				"description": "calm",
				"age": "old",
				"gender": "male",
				"use_case": "news"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/ZQe5CZNOzWyzPSCn5a3c/35734112-7b72-48df-bc2f-64d5ab2f791b.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "Zlb1dXrM653N07WRdFW3",
			"name": "Benjamin",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "British",
				"description": "articulate",
				"age": "middle-aged",
				"gender": "male",
				"use_case": "news"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/Zlb1dXrM653N07WRdFW3/daa22039-8b09-4c65-b59f-c79c48646a72.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "bIHbv24MWmeRgasZH58o",
			"name": "Logan",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "friendly",
				"age": "young",
				"gender": "male",
				"use_case": "social media"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/bIHbv24MWmeRgasZH58o/8caf8f3d-ad29-4980-af41-53f20c72d7a4.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "bVMeCyTHy58xNoL34h3p",
			"name": "Owen",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "Irish",
				"description": "excited",
				"age": "young",
				"gender": "male",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/bVMeCyTHy58xNoL34h3p/66c47d58-26fd-4b30-8a06-07952116a72c.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "cgSgspJ2msm6clMCkdW9",
			"name": "Lily",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "expressive",
				"age": "young",
				"gender": "female",
				"use_case": "conversational"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/cgSgspJ2msm6clMCkdW9/56a97bf8-b69b-448f-846c-c3a11683d45a.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "cjVigY5qzO86Huf0OWal",
			"name": "Andrew",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "friendly",
				"age": "middle-aged",
				"gender": "male",
				"use_case": "conversational"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/cjVigY5qzO86Huf0OWal/d098fda0-6456-4030-b3d8-63aa048c9070.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "flq6f7yk4E4fJM5XTYuZ",
			"name": "Christopher",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "calm",
				"age": "old",
				"gender": "male",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/flq6f7yk4E4fJM5XTYuZ/c6431a82-f7d2-4905-b8a4-a631960633d6.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "g5CIjZEefAph4nQFvHAz",
			"name": "Nathan",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "soft",
				"age": "young",
				"gender": "male",
				"use_case": "ASMR"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/g5CIjZEefAph4nQFvHAz/26acfa99-fdec-43b8-b2ee-e49e75a3ac16.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "iP95p4xoKVk53GoZ742B",
			"name": "Jonathan",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "casual",
				"age": "middle-aged",
				"gender": "male",
				"use_case": "conversational"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/iP95p4xoKVk53GoZ742B/3f4bde72-cc48-40dd-829f-57fbf906f4d7.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "jBpfuIE2acCO8z3wKNLl",
			"name": "Emma",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "childlish",
				"age": "young",
				"gender": "female",
				"use_case": "animation"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/jBpfuIE2acCO8z3wKNLl/3a7e4339-78fa-404e-8d10-c3ef5587935b.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "jsCqWAovK2LkecY7zXl4",
			"name": "Ella",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "expressive",
				"age": "young",
				"gender": "female",
				"use_case": "characters"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/jsCqWAovK2LkecY7zXl4/8e1f5240-556e-4fd5-892c-25df9ea3b593.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "nPczCjzI2devNBz1zQrb",
			"name": "Mason",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "deep",
				"age": "middle-aged",
				"gender": "male",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/nPczCjzI2devNBz1zQrb/2dd3e72c-4fd3-42f1-93ea-abc5d4e5aa1d.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "oWAxZDx7w5VEj9dCyTzz",
			"name": "Abigail",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American (South)",
				"description": "pleasant",
				"age": "young",
				"gender": "female",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/oWAxZDx7w5VEj9dCyTzz/84a36d1c-e182-41a8-8c55-dbdd15cd6e72.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "onwK4e9ZLuTAKqWW03F9",
			"name": "Gabriel",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "British",
				"description": "authoritative",
				"age": "middle-aged",
				"gender": "male",
				"use_case": "news"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/onwK4e9ZLuTAKqWW03F9/7eee0236-1a72-4b86-b303-5dcadc007ba9.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "pFZP5JQG7iQjIQuC4Bku",
			"name": "Avery",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "British",
				"description": "warm",
				"age": "middle-aged",
				"gender": "female",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/pFZP5JQG7iQjIQuC4Bku/89b68b35-b3dd-4348-a84a-a3c13a3c2b30.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "pMsXgVXv3BLzUgSXRplE",
			"name": "Savannah",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "pleasant",
				"age": "middle-aged",
				"gender": "female",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/pMsXgVXv3BLzUgSXRplE/d61f18ed-e5b0-4d0b-a33c-5c6e7e33b053.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "pNInz6obpgDQGcFmaJgB",
			"name": "Matthew",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "american",
				"description": "deep",
				"age": "middle aged",
				"gender": "male",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/pNInz6obpgDQGcFmaJgB/d6905d7a-dd26-4187-bfff-1bd3a5ea7cac.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": []
			
		},
		{
			"id": "piTKgcLEGmPE4e6mEKli",
			"name": "Chloe",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "soft",
				"age": "young",
				"gender": "female",
				"use_case": "ASMR"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/piTKgcLEGmPE4e6mEKli/c269a54a-e2bc-44d0-bb46-4ed2666d6340.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "pqHfZKP75CvOlQylNhV4",
			"name": "Joseph",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "trustworthy",
				"age": "old",
				"gender": "male",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/pqHfZKP75CvOlQylNhV4/d782b3ff-84ba-4029-848c-acf01285524d.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "t0jbNlBVZ17f02VDIeMI",
			"name": "Leon",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "raspy",
				"age": "old",
				"gender": "male",
				"use_case": "characters"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/t0jbNlBVZ17f02VDIeMI/e26939e3-61a4-4872-a41d-33922cfbdcdc.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "yoZ06aMxZJJ28mfd3POQ",
			"name": "Zachary",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "american",
				"description": "raspy",
				"age": "young",
				"gender": "male",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/yoZ06aMxZJJ28mfd3POQ/b017ad02-8d18-4456-ad92-55c85ecf6363.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": []
			
		},
		{
			"id": "z9fAnlkpzviPz146aGWa",
			"name": "Eleanor",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "American",
				"description": "witch",
				"age": "middle-aged",
				"gender": "female",
				"use_case": "characters"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/z9fAnlkpzviPz146aGWa/cbc60443-7b61-4ebb-b8e1-5c03237ea01d.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "zcAOhNBS3c14rBihAFp1",
			"name": "Angelo",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "Italian",
				"description": "foreigner",
				"age": "young",
				"gender": "male",
				"use_case": "narration"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/zcAOhNBS3c14rBihAFp1/e7410f8f-4913-4cb8-8907-784abee5aff8.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "zrHiDhphv9ZnVXBqCLjz",
			"name": "Sophie",
			"samples": null,
			"category": "premade",
			"labels": {
				"accent": "Swedish",
				"description": "childish",
				"age": "young",
				"gender": "female",
				"use_case": "animation"
			},
			"description": null,
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/zrHiDhphv9ZnVXBqCLjz/decbf20b-0f57-4fac-985b-a4f0290ebfc4.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v1",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5"
			]
			
		},
		{
			"id": "kPzsL2i3teMYv0FxEYQ6",
			"name": "Brittney",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "upbeat",
				"accent": "american",
				"gender": "female",
				"age": "young",
				"use_case": "social_media"
			},
			"description": "A young, vibrant female voice that is perfect for celebrity news, hot topics, and fun conversation. Great for YouTube channels, informative videos, how-to's, and more! ",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/kPzsL2i3teMYv0FxEYQ6/4sLh92VdgT3Hppimhb4W.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5",
				"eleven_multilingual_sts_v2"
			]
		},
		{
			"id": "1m3E2x7boso3AU9J3woJ",
			"name": "Kingsley",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "wise",
				"accent": "american",
				"gender": "male",
				"age": "middle_aged",
				"use_case": "narrative_story"
			},
			"description": "Strikingly Deep and Majestic American Old Male Voice. Perfect for Narrations.",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/1m3E2x7boso3AU9J3woJ/L7q4VQiKEPRK0dWjZDAe.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5",
				"eleven_multilingual_sts_v2"
			]
		},
		{
			"id": "Fxt4GZnlXkUGMtWSYIcm",
			"name": "Cal",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "deep",
				"accent": "american",
				"gender": "male",
				"age": "middle_aged",
				"use_case": "narrative_story"
			},
			"description": "A deep, calming, and authoritative middle-aged American male voice. Great for storytelling, ASMR, and meditations.",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/Fxt4GZnlXkUGMtWSYIcm/fHabwPggd8oEXg3yqDRL.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5",
				"eleven_multilingual_sts_v2"
			]
		},
		{
			"id": "HDA9tsk27wYi3uq0fPcK",
			"name": "Stuart",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "upbeat",
				"accent": "australian",
				"gender": "male",
				"age": "middle_aged",
				"use_case": "informative_educational"
			},
			"description": "A 40-year-old Australian male He speaks with a softer Australian accent, not the broad, stereotypical twang often associated with the Outback, but rather a gentler, more refined tone that reflects his maturity and sophistication.",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/database/user/3LUrnzimItRhFJyBGCNQfSc602Y2/voices/HDA9tsk27wYi3uq0fPcK/4AhIQcGzr8aDvnONN3bh.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_multilingual_sts_v2"
			]
		},
		{
			"id": "IsEXLHzSvLH9UMB6SLHj",
			"name": "Matt",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "calm",
				"accent": "american",
				"gender": "male",
				"age": "young",
				"use_case": "narrative_story"
			},
			"description": "A calm, soothing and slightly raspy voice that is perfect for sleep stories or meditative content.",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/database/user/sGdBGJVQV0arGe0pw33nT6jYibF2/voices/IsEXLHzSvLH9UMB6SLHj/k7R5HBr1xmw63lLfraao.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5",
				"eleven_turbo_v2",
				"eleven_multilingual_sts_v2"
			]
		},
		{
			"id": "6h73JcWWxbwrd0KhtUIE",
			"name": "Phil",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "formal",
				"accent": "american",
				"gender": "male",
				"age": "young",
				"use_case": "social_media"
			},
			"description": "A professional american voice artist reading formally.",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/database/user/3nd1bbEAXveVhnSjl194l9eYPsp1/voices/6h73JcWWxbwrd0KhtUIE/A8IU8CqVnspfhzZdxMOd.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2_5",
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_multilingual_sts_v2"
			]
		},
		{
			"id": "nBoLwpO4PAjQaQwVKPI1",
			"name": "Amelia",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "crisp",
				"accent": "australian",
				"gender": "female",
				"age": "young",
				"use_case": "narrative_story"
			},
			"description": "A young Australian female voice. Perfect for narration, advertising and character voicing.",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/database/user/zMfVYhWBQcWhDcafePbjm8CD5Lx2/voices/nBoLwpO4PAjQaQwVKPI1/pEX4zUYPQmsyqSF5AcW4.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5",
				"eleven_turbo_v2"
			]
		},
		{
			"id": "GzE4TcXfh9rYCU9gVgPp",
			"name": "Alex",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "confident",
				"accent": "american",
				"gender": "male",
				"age": "middle_aged",
				"use_case": "informative_educational"
			},
			"description": "American, eLearning, Friendly, approachable, warm and professional, great for narration",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/database/user/ZfHRPQw8XMQ9pCmMyjTV9BeXbOp1/voices/GzE4TcXfh9rYCU9gVgPp/nU7sMeJfVL9tfcF9Xnt5.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_turbo_v2_5",
				"eleven_multilingual_v2",
				"eleven_multilingual_sts_v2"
			]
		},
		{
			"id": "OhisAd2u8Q6qSA4xXAAT",
			"name": "Pablo",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "casual",
				"accent": "spanish",
				"gender": "male",
				"age": "middle_aged",
				"use_case": "characters_animation"
			},
			"description": "Pablo is a middle-aged male with a Mexican accent. Fairly enthusiastic delivery, with occasional lisps.",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/database/user/29ttw5FiLeOPLSX0f4drayIgBM92/voices/OhisAd2u8Q6qSA4xXAAT/kryZ7tSlnIXBWgqDeJcE.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5",
				"eleven_turbo_v2",
				"eleven_multilingual_sts_v2"
			]
		},
		{
			"id": "c6kFzbpMaJ8UMD5P6l72",
			"name": "Seth",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "pleasant",
				"accent": "american",
				"gender": "male",
				"age": "middle_aged",
				"use_case": "conversational"
			},
			"description": "Midwestern-American Male. Classic guy-next-door. Real, Genuine and believable. Professional sample with clear articulate tone and inflection. Suitable for young to middle-aged voice needs & conversations. ",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/c6kFzbpMaJ8UMD5P6l72/ydkMjW1w6SureFn76BQf.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_turbo_v2_5",
				"eleven_multilingual_v2"
			]
		},
		{
			"id": "Se2Vw1WbHmGbBbyWTuu4",
			"name": "Allison",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "pleasant",
				"accent": "british",
				"gender": "female",
				"age": "young",
				"use_case": "narrative_story"
			},
			"description": "A smooth British accent that is elegant and engaging. A perfect choice for audiobook narration and YouTube. ",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/Se2Vw1WbHmGbBbyWTuu4/3criMPMqBy1hVGIVhW2Q.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5",
				"eleven_multilingual_sts_v2"
			]
		},
		{
			"id": "vYENaCJHl4vFKNDYPr8y",
			"name": "Riya",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "professional",
				"accent": "indian",
				"gender": "female",
				"age": "middle_aged",
				"use_case": "narrative_story"
			},
			"description": "Riya is the pen name of one of the most professional voice-over artists you will ever hear on ElevenLabs. Her voice has already been a part of major conversational bots in the past. Use it for all your professional and conversational needs, such as social media, documentaries, audiobooks,\r\ncorporate videos, and so on.",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/vYENaCJHl4vFKNDYPr8y/SlJHmXj7gMvZe2nanAnq.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_multilingual_v2",
				"eleven_turbo_v2_5",
				"eleven_multilingual_sts_v2"
			]
		},
		{
			"id": "QSeHT5rPTxfFUSz6eRQk",
			"name": "Elisabeth",
			"samples": null,
			"category": "generated",
			"labels": {
				"accent": "american",
				"descriptive": "pleasant",
				"age": "young",
				"gender": "female",
				"use_case": "narrative_story"
			},
			"description": "A calm youg female voice. Great for guided meditations",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/DUlqb4sMu2gK8EEQx88MJY3Cyfn2/voices/wqp0LnhTCPJDxc2CT7US/9a3ad8b3-c3ed-4805-b71b-770a31e22500.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": []
		},
		{
			"id": "7fbQ7yJuEo56rYjrYaEh",
			"name": "John",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "calm",
				"accent": "american",
				"gender": "male",
				"age": "middle_aged",
				"use_case": "narrative_story"
			},
			"description": "Middle aged American male accent. Voice is Intimate and warm. Perfect for audiobooks.",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/7fbQ7yJuEo56rYjrYaEh/6fgTpDxjGJqiV9bvPnsZ.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_turbo_v2_5",
				"eleven_multilingual_v2"
			]
		},
		{
			"id": "0OM99NXHYJeR0tA9W46o",
			"name": "Brandon",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "confident",
				"accent": "american",
				"gender": "male",
				"age": "middle_aged",
				"use_case": "narrative_story"
			},
			"description": "Imagine a voice that wraps around you like a cozy blanket on a cold winter night. It's deep and rich, resonating with a warmth that immediately puts you at ease. There's a smoothness to it, like a perfectly brewed cup of coffee, with just the right amount of mellow undertones.\nThis voice carries the unmistakable charm of the American Midwest\u2014steady and reliable, yet with a friendly, approachable vibe that makes you feel like you're chatting with an old friend. ",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/0OM99NXHYJeR0tA9W46o/tircw8FzwcdmG8tQY0UQ.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_multilingual_v2",
				"eleven_turbo_v2"
			]
		},
		{
			"id": "Oq0cIHWGcnbOGozOQv0t",
			"name": "Vidhi",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "professional",
				"accent": "indian",
				"gender": "female",
				"age": "young",
				"use_case": "narrative_story"
			},
			"description": "Indian Young & Bold Female Voice, Great for narrations.",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/J7muYH0YzgXnuAhAb9YSzifwqbi2/voices/Oq0cIHWGcnbOGozOQv0t/f8e105ad-d78f-46fb-8b39-3f803d09e1d8.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_turbo_v2_5",
				"eleven_multilingual_v2",
				"eleven_multilingual_sts_v2"
			]
		},
		{
			"id": "LruHrtVF6PSyGItzMNHS",
			"name": "Benjamin",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "relaxed",
				"accent": "american",
				"gender": "male",
				"age": "young",
				"use_case": "informative_educational"
			},
			"description": "A relaxed and friendly American young male.",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/LruHrtVF6PSyGItzMNHS/nT4q5imSNuyohopEWe5D.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2_5",
				"eleven_multilingual_v2",
				"eleven_turbo_v2"
			]
		},
		{
			"id": "aK7wAfnPDM1fZL1JTkNB",
			"name": "Alexis",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "confident",
				"accent": "american",
				"gender": "female",
				"age": "middle_aged",
				"use_case": "characters_animation"
			},
			"description": "Confident American Female",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/aK7wAfnPDM1fZL1JTkNB/xuV2ejjOd58UBgvrUobA.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2",
				"eleven_turbo_v2_5",
				"eleven_multilingual_v2"
			]
		},
		{
			"id": "xLWfYhsLPCTMKco1P38p",
			"name": "Scarlett",
			"samples": null,
			"category": "professional",
			"labels": {
				"language": "en",
				"descriptive": "strong",
				"accent": "american",
				"gender": "female",
				"age": "young",
				"use_case": "narrative_story"
			},
			"description": "Deep, female voice with a gritty and intense tone. Ideal for motivational content.",
			"premium": true,
			"preview_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/xLWfYhsLPCTMKco1P38p/5tLZ8oCpWgdfyK1k7f0f.mp3",
			"available_for_tiers": [],
			"settings": null,
			"high_quality_base_model_ids": [
				"eleven_turbo_v2_5",
				"eleven_turbo_v2",
				"eleven_multilingual_v2"
			]
		}
	
];

const newVoices = [
    
]

export default voices;
