import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Generate.css';
import { useTranslation } from 'react-i18next';
import Select from 'react-select'; // ensure react-select is installed
import { DataContext } from '../DataContext';
import { languageOptions } from './LanguageOptions';
import DataTable from 'react-data-table-component';

function GenerateMultipleSeries({ getToken }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [prompt, setPrompt] = useState("generate a complete list of potential audioguides title. each of these audioguides will have a different angle/focus. Be creative, some can be simple, some general, some can be very focus, any idea is welcome. We want audioguides to cover the city of milan.");
  const [language, setLanguage] = useState("en");
  const [additionalLanguages, setAdditionalLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { voices } = useContext(DataContext);
  const [audioStyle, setAudioStyle] = useState("podcasts");
  const [voice, setVoice] = useState("");
  const [numOfMinutes, setNumOfMinutes] = useState(5);
  const [generatedIdeas, setGeneratedIdeas] = useState([]); // Array for holding title/desc pairs
  const [tableData, setTableData] = useState([]); // Array to display the generated title/desc pairs

  // Test mode flag
  const testMode = true; // Set this to true to skip the API call and use sample data

  const sampleData = [
    {
        "title": "Milano Moda: The Fashion Capital Chronicles",
        "desc": "Step onto the catwalk of history with 'Milano Moda.' In each episode, we'll explore the fashion houses that made Milan a capital of style, detailing their rise, influence, and the secrets behind their luxurious fabrics. Discover the stories of fashion icons and the trends they set."
    },
    {
        "title": "Milan Mystique: Unveiling The Hidden Gems",
        "desc": "Join us as we wander off the beaten path in 'Milan Mystique.' This series guides listeners through Milan's lesser-known locales, revealing secret histories, local legends, and tucked-away trattorias. Perfect for the curious traveler eager to experience Milan's authentic charm."
    },
    {
        "title": "Caffeinated in Milan: A Coffee Culture Tour",
        "desc": "\u2018Caffeinated in Milan\u2019 is your audio guide to the art of Italian coffee. Each episode visits a different Milanese caf\u00e9, diving into the rituals, history, and social buzz of Italy\u2019s coffee culture. Learn what makes a perfect espresso and the secrets of baristas."
    },
    {
        "title": "Milan at the Mic: Sounds of the City",
        "desc": "This immersive audio experience, 'Milan at the Mic,' takes listeners on a sonic journey through Milan's vibrant soundscapes. From the buzz of the Navigli to the echoes of the Duomo, each episode is a symphony of the city's auditory treasures."
    },
    {
        "title": "Milan on a Plate: Culinary Tales",
        "desc": "\u2018Milan on a Plate\u2019 serves up the flavors of the city as an audio feast. Discover the stories behind Milanese cuisine, from saffron-infused risotto to the sweet twist of panettone. Each episode pairs local chefs and food experts to dish out Milan\u2019s culinary history."
    },
    {
        "title": "Architectural Milano: Foundations of the City",
        "desc": "Delve into 'Architectural Milano' to explore the city's skyline from Roman times to modern marvels. We'll uncover the architects who shaped Milan and their creations that have stood the test of time, influencing the fabric of this metropolitan muse."
    },
    {
        "title": "Milan\u2019s Canvas: A Journey Through Art",
        "desc": "Art enthusiasts, 'Milan's Canvas' is your auditory gallery. We'll guide you through Milan\u2019s artistic masterpieces, from Da Vinci's Last Supper to contemporary installations. Learn about the art that adorns the city and the stories of those who painted its history."
    },
    {
        "title": "Green Milan: Navigating the Urban Oasis",
        "desc": "\u2018Green Milan\u2019 is an eco-tour of the city's parks and gardens. Each episode blossoms with the sounds of nature and insightful narratives on Milan\u2019s green spaces. Discover the haven of Sempione Park or the tranquil pathways of the Orto Botanico di Brera."
    },
    {
        "title": "Milan Under the Stars: Astronomy and Legends",
        "desc": "Gaze up with 'Milan Under the Stars,' a series linking the city's architecture to the cosmos. Learn about Milan's astronomical history, how the stars have influenced its layout, and the myths twinkling above this urban landscape each night."
    },
    {
        "title": "Innovative Milan: The Tech and Design Hub",
        "desc": "Plug into 'Innovative Milan,' where we delve into the city's forward-thinking spirit in technology and design. Each episode highlights breakthroughs, interviews with innovators, and explores how Milan became a launchpad for cutting-edge ideas and trends."
    }
];

  useEffect(() => {
    if (voices.length && !voice) {
      setVoice(voices[0].id);
    }
  }, [voices, voice]);

  const additionalLanguageOptions = languageOptions.filter(option => option.value !== language);

  const generateIdeas = async () => {
    if (testMode) {
      // Use sample data if testMode is true
      setGeneratedIdeas(sampleData);
      return;
    }

    if (!prompt) {
      window.alert(t('enterPrompt'));
      return;
    }
    setIsLoading(true);
    try {
      const payload = {
        prompt: prompt,
        voice: voices.find(v => v.id === voice),
        language: language,
        additional_languages: additionalLanguages.length ? additionalLanguages.map(lang => lang.value) : [],
        style: audioStyle,
        minutes: numOfMinutes,
      };

      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/series/multiple/ideas`, payload, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json'
        }
      });
      console.log("response");
      console.log(response);
      console.log("response.data");
      console.log(response.data.seriesIdeas.list);
      var index = 0;
      response.data.seriesIdeas.list.forEach(idea => {
        idea.id = index++;
      }
      );
      setGeneratedIdeas(response.data.seriesIdeas.list || []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error generating ideas:", error);
      alert(t('errorGeneratingIdeas'));
    }
  };

  const submitAllSeries = async () => {
    if (!generatedIdeas.length) {
      window.alert(t('noSeriesToSubmit'));
      return;
    }
    setIsLoading(true);
    try {
      for (const idea of generatedIdeas) {
        const payload = {
          title: idea.title,
          desc: idea.desc,
          language,
          additional_languages: additionalLanguages.length ? additionalLanguages.map(lang => lang.value) : [],
          accessToken: await getToken(),
          style: audioStyle,
          voice: voices.find(v => v.id === voice),
          minutes: numOfMinutes,
        };
        await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/series/generate`, payload, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            'Content-Type': 'application/json'
          }
        });
      }
      setIsLoading(false);
      navigate('/app/content');
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting series:", error);
      alert(t('errorSubmittingSeries'));
    }
  };

  const deleteIdea = (id) => {
    setGeneratedIdeas(generatedIdeas.filter(idea => idea.id !== id));
  };

  // Define table columns
  const columns = [
    {
      name: t('title'),
      selector: row => row.title,
      sortable: true,
    },
    {
      name: t('description'),
      selector: row => row.desc,
      sortable: true,
    },
    {
      name: t('actions'),
      cell: row => <button onClick={() => deleteIdea(row.id)}>{t('delete')}</button>,
    },
  ];

  if (isLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
        <div className="loading-text">{t('loading')}</div>
      </div>
    );
  }

  return (
    <div className="content-package" id="generator">
      <h1 id="baseSelectorTitle">{t('createMultipleSeriesTitle')}</h1>

      {generatedIdeas.length === 0 && (
        <>
          <p className="form-label">{t('enterPrompt')}</p>
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder={t('enterPromptPlaceholder')}
          />

          <p className="form-label">{t('selectStyle')}</p>
          <select value={audioStyle || ''} onChange={(e) => setAudioStyle(e.target.value)}>
            <option value="podcasts">{t('podcasts')}</option>
            <option value="audiobooks">{t('audiobooks')}</option>
            <option value="lectures">{t('lectures')}</option>
            <option value="storytelling">{t('storytelling')}</option>
            <option value="audioguides">{t('audioguides')}</option>
          </select>

          <p className="form-label">{t('selectLanguage')}</p>
          <select value={language} onChange={(e) => setLanguage(e.target.value)}>
            {languageOptions.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>

          <p className="form-label">{t('selectAdditionalLanguages')}</p>
          <Select
            isMulti
            options={additionalLanguageOptions}
            onChange={setAdditionalLanguages}
            className="basic-multi-select"
            classNamePrefix="select"
          />

          {/* <p className="form-label">{t('selectLength')}</p>
          <select value={numOfMinutes || 5} onChange={(e) => setNumOfMinutes(e.target.value)}>
            <option value="5">5 minutes</option>
            <option value="10">10 minutes</option>
          </select> */}

          <p className="form-label">{t('selectVoice')}</p>
          <select value={voice} onChange={(e) => setVoice(e.target.value)}>
            {voices.map((v) => (
              <option key={v.id} value={v.id}>
                {v.name} - {v.labels.accent}, {v.labels.age}, {v.labels.gender}, {v.labels.usecase}
                {v.premium ? " (premium)" : ""}
              </option>
            ))}
          </select>

          <button onClick={generateIdeas}>{t('generateIdeasButton')}</button>
        </>
      )}

      {generatedIdeas.length > 0 && (
        <>
          <DataTable
            columns={columns}
            data={generatedIdeas}
            pagination
            highlightOnHover
            responsive
            noHeader
          />
          <button onClick={submitAllSeries}>{t('submitAllSeriesButton')}</button>
        </>
      )}
    </div>
  );
}

export default GenerateMultipleSeries;
