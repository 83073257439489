import React, { createContext, useState } from "react";
import {
  fetchAllMyContent,
  fetchSeries,
  fetchEpisodes,
  fetchTrending,
} from "./components/Fetching";
import allVoices from './Voices';

export const DataContext = createContext();

function extractLanguage(acceptLanguage) {
  if (!acceptLanguage) {
    return acceptLanguage;
  }
  // Split the input by comma to handle cases where there are multiple languages
  const languages = acceptLanguage.split(",");

  // Map each language tag to its ISO language code
  const isoLanguages = languages.map((lang) => {
    // Extract the part before the first hyphen or semicolon
    const isoCode = lang.split("-")[0].split(";")[0].trim();
    return isoCode;
  });

  // Return the first ISO language code, or an array of all ISO codes if you prefer
  return isoLanguages[0];
}

export const DataProvider = ({ children }) => {
  const [episodes, setEpisodes] = useState([]);
  const [series, setSeries] = useState([]);
  const [trending, setTrending] = useState([]);
  const [myEpisodes, setMyEpisodes] = useState([]);
  const [mySeries, setMySeries] = useState([]);
  const [voices, setVoices] = useState(allVoices);
  const [gif, setGif] = useState([]);
  const [userDetails, setUserDetails] = useState({}); // State for user details
  const [userSubscription, setUserSubscription] = useState(undefined); // State for user details
  const [language, setLanguageState] = useState(
    extractLanguage(localStorage.getItem("language")) ||
      extractLanguage(navigator.language) ||
      "en"
  );

  // Add loading states
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [isLoadingVoices, setIsLoadingVoices] = useState(false);
  const [isLoadingMyContent, setIsLoadingMyContent] = useState(false);
  const [isLoadingUserDetails, setIsLoadingUserDetails] = useState(false); // Loading state for user details
  const [isLoadingUserSubscription, setIsLoadingUserSubscription] =
    useState(true); // Loading state for user details

  // Utility function to shuffle an array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const loadContent = async (getToken, force = false) => {
    if (
      !force &&
      (isLoadingContent || series.length > 0 || episodes.length > 0)
    ) {
      return;
    }
    console.log("Loading content");
    await setIsLoadingContent(true);

    try {
      const token = await getToken();
      const episodesData = await fetchEpisodes(token, language);
      var episodesOptimised = shuffleArray(episodesData);
      setEpisodes(episodesOptimised);

      const seriesData = await fetchSeries(token, language);
      var seriesOptimised = shuffleArray(seriesData);
      setSeries(seriesOptimised);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingContent(false);
    }
  };

  const loadVoices = async (getToken, force = false) => {
    // if (!force && (isLoadingVoices || voices.length > 0)) return;
    // setIsLoadingVoices(true);
    // try {
    //   const token = await getToken();
    //   const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/voice/all`, {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       "Authorization": `Bearer ${token}`,
    //       'Accept-Language': language,
    //     },
    //   });
    //   if (!response.ok) {
    //     throw new Error('Network response was not ok');
    //   }
    //   const data = await response.json();
    //   setVoices(data.voices.map(voice => ({ ...voice })));
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setIsLoadingVoices(false);
    // }
  };

  const loadTrending = async (getToken, force = false) => {
    if (!force && trending.length > 0) return trending;
    try {
      const token = await getToken();
      const data = await fetchTrending(token, language);
      setTrending(data);
    } catch (error) {
      console.error(error);
    }
  };

  const loadMyContent = async (getToken, force = false) => {
    if (
      !force &&
      (isLoadingMyContent || mySeries.length > 0 || myEpisodes.length > 0)
    )
      return;
    setIsLoadingMyContent(true);

    try {
      const token = await getToken();
      const data = await fetchAllMyContent(token, language);
      setMyEpisodes(data.episodes);
      setMySeries(data.series);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMyContent(false);
    }
  };

  const getGif = async (force = false) => {
    if (!force && gif.length > 0) return gif;
    var randomNumber = Math.random();
    randomNumber = Math.floor(randomNumber * 21);
    setGif("/gif/wait/wait" + randomNumber + ".gif");
    return gif;
  };

  const getUserDetails = async (getToken) => {
    setIsLoadingUserDetails(true); // Set loading state to true
    try {
      const token = await getToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/user/subscription/limit`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Accept-Language": language,
          },
        }
      );

      console.log(response);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setUserDetails(data); // Update the userDetails state with the fetched data
      setIsLoadingUserDetails(false);
      return data; // Return the fetched data
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUserDetails(false); // Set loading state back to false
    }
  };

  const getUserSubscription = async (getToken, forceLoad = false) => {
    console.log("getUserSubscription");
    console.log(userSubscription);
    if (userSubscription != undefined && !forceLoad) return userSubscription;
    setIsLoadingUserSubscription(true); // Set loading state to true
    try {
      const token = await getToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/user/subscription/exists`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Accept-Language": language,
          },
        }
      );

      console.log(response);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setUserSubscription(data); // Update the userSubscription state with the fetched data
      console.log(data);
      setIsLoadingUserSubscription(false);
      return data; // Return the fetched data
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUserSubscription(false); // Set loading state back to false
    }
  };

  const setLanguage = (newLanguage) => {
    var languageToUse = extractLanguage(newLanguage);
    localStorage.setItem("language", languageToUse);
    setLanguageState(languageToUse);
    setEpisodes([]);
    setSeries([]);
    setTrending([]);
    setMyEpisodes([]);
    setMySeries([]);
  };

  return (
    <DataContext.Provider
      value={{
        episodes,
        series,
        mySeries,
        myEpisodes,
        loadContent,
        userSubscription,
        getUserSubscription,
        isLoadingUserSubscription,
        trending,
        loadTrending,
        loadMyContent,
        loadVoices,
        voices,
        getGif,
        isLoadingContent,
        isLoadingMyContent,
        gif,
        getUserDetails,
        userDetails,
        isLoadingUserDetails,
        language, // Provide the language in the context
        setLanguage, // Provide a way to change the language
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
