import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./Generate.css";
import { fetchSerie, fetchSearch } from "./Fetching";
import { pushGTMEvent } from "../GoogleAnalytics";
import { DataContext } from "../DataContext";
import Loading from "./Loading";
import Thumbnail from "./Thumbnail";
import VoiceSelector from "./VoiceSelector"; // Import the VoiceSelector component

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faLink,
  faFire,
  faCircleQuestion,
  faFilePen,
  faSync,
  faCirclePlus,
  faCubesStacked,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

const SUBSCRIPTION_LIMITS = {
  free: 0,
  pro: 40,
  studio: 300,
};

const OptionCard = ({ id, title, description, onSelect, option, card }) => {
  return (
    <div className="option-card" onClick={() => onSelect(option)}>
      <h3>
        {" "}
        <FontAwesomeIcon icon={card.icon} /> {title}
      </h3>
      <p>{description}</p>
      {/* <div
        style={{
          width: "100%",
          height: "0",
          paddingBottom: "100%",
          position: "relative",
        }}
      >
        <img
          src={card.gifLink}
          width="100%"
          height="100%"
          style={{ position: "absolute" }}
          frameBorder="0"
          className="giphy-embed"
          allowFullScreen
        ></img>
      </div> */}
    </div>
  );
};

function Generate({ getToken }) {
  const { t } = useTranslation(); // Initialize useTranslation hook
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth0();

  const [question, setQuestion] = useState("");
  const [titleToogle2, setTitleToogle2] = useState("");
  const [titleToogle3, setTitleToogle3] = useState("");
  const [descriptionToogle1, setDescriptionToogle1] = useState("");
  const [descriptionToogle2, setDescriptionToogle2] = useState("");
  const [descriptionToogle3, setDescriptionToogle3] = useState("");
  const browserLanguage = "en"; //  navigator.language ||
  const [language, setLanguage] = useState(browserLanguage);
  const [numOfMinutes, setNumOfMinutes] = useState(5);
  const [generatingEpisode, setGeneratingEpisode] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [limitReached, setLimitReached] = useState(false);
  const {
    voices,
    loadVoices,
    mySeries,
    loadMyContent,
    isLoadingMyContent,
    getUserDetails,
  } = useContext(DataContext);

  
  const [selectedVoice, setSelectedVoice] = useState("");

  const [gettingSerieDetails, setGettingSerieDetails] =
    useState(isLoadingMyContent);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [episodeId, setEpisodeId] = useState(null);
  const [series, setSeries] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [loadingSeries, setLoadingSeries] = useState(true);
  const [toggleOption, setToggleOption] = useState(null); // 1: question, 2: link, 3: content
  const [link, setLink] = useState("");
  const [content, setContent] = useState("");
  const [imgPodcast, setImgPodcast] = useState("");
  const [serieContent, setSerieContent] = useState(null);
  const [audioStyle, setAudioStyle] = useState("podcasts");
  const [imageData, setImageData] = useState(null); // State to hold the captured image data
  const [voice, setVoice] = useState("653286775739d1426af46b08");
  const [playingVoice, setPlayingVoice] = useState(null); // To track the playing voice preview
  const serieRef = useRef(null);
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const audioRef = useRef(null);

  pushGTMEvent("page_view", "Page Interaction", "/app/generate/episode");

  const admin = process.env.REACT_APP_ADMINS.split(",");

  const optionCards = [
    {
      id: "prompt",
      icon: faCircleQuestion,
      title: t("askAndCreate"),
      description: t("askAndCreateDescription"),
      option: 1,
      gifLink: "/gif/question.gif",
    },
    {
      id: "link",
      icon: faLink,
      title: t("fromWebToAudio"),
      description: t("fromWebToAudioDescription"),
      option: 2,
      gifLink: "/gif/link.gif",
      gifLink2:
        "https://giphy.com/gifs/myspace-website-memories-xT9IgHq4eDQKKCHqAo",
    },
    {
      id: "content",
      icon: faFilePen,
      title: t("yourStoryYourVoice"),
      description: t("yourStoryYourVoiceDescription"),
      option: 3,
      gifLink: "/gif/content.gif",
    },
  ];

  const searchBeforeGenerate = async (title) => {
    await setIsLoadingSearch(true);
    pushGTMEvent("generate", "searchBeforeGenerate", "episode");
    console.log("searching for: ", question);

    if (toggleOption === 1) {
      setIsLoadingSearch(true);
      var token = await getToken();
      const searchResults = await fetchSearch(question, token);

      if (searchResults.episodes.length > 0) {
        setIsLoadingSearch(false);
        console.log(searchResults);
        var results = searchResults.episodes
          .filter((episode) => episode.score > 10)
          .slice(0, Math.min(5, searchResults.episodes.length));
        if (results.length > 0) {
          setSearchResults(results);
          setShowSearchResults(true);
        } else {
          generateEpisode();
        }
      } else {
        generateEpisode();
      }
    } else {
      generateEpisode();
    }
  };

  const handleCapture = useCallback((event) => {
    pushGTMEvent("generate", "Capture Image", "episode");
    const fileReader = new FileReader();
    const file = event.target.files[0];

    fileReader.onloadend = () => {
      setImageData(fileReader.result);
    };

    if (file) {
      fileReader.readAsDataURL(file);
    }
  }, []);

  async function load() {
    if (id) {
      await loadSerie(id);
    }
    await loadMyContent(getToken);
    await setLoadingSeries(false);
  }

  useEffect(() => {
    if (voices.length) {
      setVoice(voices[0].id);
    }
  }, [voices]);

  useEffect(() => {
    if (!mySeries.length && loadingSeries) {
      load();
    } else {
      console.log("loaded");
      console.log(mySeries);
      setLoadingSeries(false);
    }
  }, [mySeries, loadMyContent, getToken]);

  useEffect(() => {
    if (isLoadingMyContent) {
      setGettingSerieDetails(true);
    } else {
      setGettingSerieDetails(false);
    }
  }, [isLoadingMyContent]);

  useEffect(() => {
    const loadUserDetails = async () => {
      await getUserDetails(getToken).then((details) => {
        console.log("userDetails:" + details);
        setUserDetails(details);

        const subscriptionType = details?.current_subscription || "free";
        const limit = SUBSCRIPTION_LIMITS[subscriptionType] * 60; // Convert limit to seconds

        if (details && details.total_audio_length_seconds >= limit) {
          setLimitReached(true);
        }
      });
    };

    if (!userDetails) {
      loadUserDetails();
    }

    if (!mySeries.length && loadingSeries) {
      load();
    }
  }, []);

  useEffect(() => {
    if (episodeId) {
      setTimeout(() => {
        navigate(`/app/content/episode/${episodeId}`);
      }, 5000);
    }
  }, [episodeId]);

  const selectingSerie = async (id) => {
    const serieToUse = mySeries.find((user) => user.id === id);
    await setSelectedSeries(id);
    await setSerieContent(serieToUse);
  };

  const loadSerie = async (id) => {
    await setGettingSerieDetails(true);
    var token = await getToken();
    try {
      const data = await fetchSerie(id, token);
      setSerieContent(data);
    } catch (error) {
      console.error("Error fetching serie:", error);
    }
    await setGettingSerieDetails(false);
  };

  useEffect(() => {
    if (id && !serieContent) {
      loadSerie(id);
    } else if (id && serieContent) {
      setGettingSerieDetails(false);
    }
  }, [id]);

  useEffect(() => {
    if (voices && voices.length > 0 && !selectedVoice) {
      setSelectedVoice(voices[0].id); // Set initial voice when voices are loaded
    }
  }, [voices, selectedVoice]);

  const generateEpisode = async () => {
    pushGTMEvent("generate", "Generate Episode", id || selectedSeries);

    if (!(id || selectedSeries !== "")) {
      window.alert("Please select a series to append episode to");
      return;
    }

    setGeneratingEpisode(true);

    try {
      const token = await getToken();
      console.log(token);

      const voiceData = voices.find((v) => v.id === voice);

      if (toggleOption === 1) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/episode/generate`,
          {
            title: question,
            desc: descriptionToogle1,
            additional_languages: serieContent.additional_languages || [],
            language: serieContent.language || "en",
            voice: voiceData,
            img: serieContent.img,
            series: id || selectedSeries,
            minutes: numOfMinutes,
            style: audioStyle,
            prompt: [
              {
                role: "system",
                content: `You are a ${audioStyle} writer, good at explaining things concisely. You write in ${language}. ${
                  numOfMinutes != "no-limit"
                    ? "You only give a " + numOfMinutes + " sentence answers."
                    : ""
                }. Write it nicely to read, not too polite not too crazy. Your answer should be a 1 time content, fully complete.`,
              },
              {
                role: "user",
                content: `title: ${question}, description: ${descriptionToogle1}, just give the content of the speech.`,
              },
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response.data);
        setEpisodeId(response.data.episode._id);
      } else if (toggleOption === 2) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/episode/generate-url`,
          {
            desc: descriptionToogle2,
            title: titleToogle2,
            additional_languages: serieContent.additional_languages || [],
            language: serieContent.language || "en",
            img: serieContent.img,
            style: audioStyle,
            voice: voiceData,
            series: id || selectedSeries,
            url: link,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response.data);
        setEpisodeId(response.data.episode._id);
      } else if (toggleOption === 3) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/episode/generate-content`,
          {
            title: titleToogle3,
            desc: descriptionToogle3,
            additional_languages: serieContent.additional_languages || [],
            language: serieContent.language || "en",
            accessToken: token,
            voice: voiceData,
            img: serieContent.img,
            style: audioStyle,
            series: id || selectedSeries,
            original_content: content,
            content: content,
            prompt: [
              {
                role: "system",
                content: `You are a ${audioStyle} writer, good at explaining things concisely. You write in ${language}. ${
                  numOfMinutes != "no-limit"
                    ? "You only give a " + numOfMinutes + " sentence answers."
                    : ""
                }. write it nicely to read, not too polite not too crazy.`,
              },
              {
                role: "user",
                content:
                  "Use the content: " +
                  content +
                  ".  And follow these additional instructions: " +
                  question +
                  ".  description: " +
                  descriptionToogle3,
              },
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response.data);
        setEpisodeId(response.data.episode._id);
      }
    } catch (error) {
      setIsLoadingSearch(false);
      console.error("Error generating episode:", error);
    }
  };

  const handleVoiceChange = (voiceId) => {
    console.log("Voice changed to:", voiceId);
    setSelectedVoice(voiceId); // Handle voice change
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => setPlayingVoice(null);
    }
  }, []);

  if (limitReached && userDetails && !admin.includes(user.sub)) {
    return (
      <div className="limit-reached-screen">
        <h2>{t("limitReached")}</h2>
        <p>
          {t("nextGeneration")} {userDetails.next_date}
        </p>
        <p>
          {t("subscriptionType")} {userDetails.current_subscription}
        </p>
        <p>
          {t("audioGenerated")}{" "}
          {Math.round(userDetails.total_audio_length_seconds / 60)}{" "}
          {t("minutesOutOf")}{" "}
          {(userDetails ? userDetails.limit_seconds : 0) / 60} {t("minutes")}.
        </p>
      </div>
    );
  }

  if (gettingSerieDetails) {
    return <Loading />;
  }

  if (loadingSeries && !id) {
    return <Loading />;
  }

  if (generatingEpisode) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
        <div className="loading-text">{t("generating")}</div>
      </div>
    );
  }

  if (episodeId && isLoading) {
    return <Loading />;
  }

  if (isLoadingSearch) {
    return <Loading />;
  }

  if (!id && !selectedSeries) {
    return (
      <div className="content-package thumbnails-wrap" id="generator">
        <h4 id="baseSelectorTitle">
          {t("selectSeriesTitle")} (
          {userDetails
            ? Math.round(userDetails.total_audio_length_seconds / 60)
            : "?"}{" "}
          / {userDetails ? Math.round(userDetails.limit_seconds / 60) : "?"}{" "}
          {t("minutes")})
        </h4>
        <p>{t("seriesDescription")}</p>
        <div className="series-thumbnails-container">
          <div
            id="create-series-btn-generator"
            onClick={() => navigate("/app/generate/serie")}
          >
            <FontAwesomeIcon icon={faCirclePlus} />
            <a style={{ textAlign: "center" }}>{t("createSeries")}</a>
          </div>
          {userDetails &&
            (userDetails.limit_seconds / 6 -
              userDetails.total_audio_length_seconds / 60 >
              100 ||
              admin.includes(user.sub)) && (
              <div
                id="create-series-btn-generator"
                onClick={() => navigate("/app/generate/complete/serie")}
              >
                <FontAwesomeIcon icon={faCubesStacked} />
                <a style={{ textAlign: "center" }}>{t("generateComplete")}</a>
              </div>
            )}
          {mySeries.length > 0 &&
            mySeries.map((serie) => (
              <div className="episode" key={serie.id}>
                <div
                  className="episode-cover"
                  style={{ cursor: "pointer" }}
                  onClick={() => selectingSerie(serie.id)}
                >
                  <img
                    className="postImg"
                    src={serie.img || "kupeel2.png"}
                    alt={serie.title}
                  />
                </div>
                <div className="episode-info">
                  <p className="episode-title">{serie.title}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  if (!toggleOption) {
    return (
      <>
        <h1 id="baseSelectorTitle">{t("selectBase")}</h1>
        <div className="option-cards-container">
          {optionCards.map((card) => (
            <OptionCard
              key={card.id}
              id={card.id}
              card={card}
              title={card.title}
              option={card.option}
              description={card.description}
              onSelect={setToggleOption}
            />
          ))}
        </div>
      </>
    );
  }

  if (!voices) {
    return <Loading />;
  }

  if (showSearchResults) {
    return (
      <div className="content-package thumbnails-wrap" id="generator">
        <h4 id="baseSelectorTitle">{t("noResultsFound")}</h4>
        <div className="series-thumbnails-container">
          <div
            id="create-series-btn-generator"
            onClick={() => generateEpisode()}
          >
            <FontAwesomeIcon icon={faFire} />
            <a>{t("generate")}</a>
          </div>
          {searchResults.length > 0 &&
            searchResults.map((episode) => (
              <div className="episode" key={episode.id}>
                <div
                  className="episode-cover"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/app/episode/" + episode.id)}
                >
                  <img
                    className="postImg"
                    src={episode.img}
                    alt={episode.title}
                  />
                </div>
                <div className="episode-info">
                  <p className="episode-title">{episode.title}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  return (
    <div className="content-package" id="generator">
      <h1 id="baseSelectorTitle">{t("generateEpisode")}</h1>
      <p className="form-label">
        {t("selectedSeries")}:{" "}
        <a style={{ fontSize: "18px", fontWeight: 700 }}>
          {serieContent.title}
        </a>
      </p>

      {toggleOption === 1 && (
        <>
          <p className="form-label">{t("enterQuestionOrTitle")}</p>
          <input
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder={t("enterQuestionOrTitle")}
          />
          <p className="form-label">{t("enterDescription")}</p>
          <input
            type="text"
            value={descriptionToogle1}
            onChange={(e) => setDescriptionToogle1(e.target.value)}
            placeholder={t("enterDescription")}
          />
          <p className="form-label">{t("selectStyle")}</p>
          <select
            value={audioStyle || ""}
            onChange={(e) => setAudioStyle(e.target.value)}
          >
            <option value="podcasts">{t("podcasts")}</option>
            <option value="lectures">{t("lectures")}</option>
            <option value="storytelling">{t("storytelling")}</option>
            <option value="audioguides">{t("audioguides")}</option>
          </select>
        </>
      )}

      {toggleOption === 2 && (
        <>
          <p className="form-label">{t("enterLinkTitle")}</p>
          <input
            type="text"
            value={titleToogle2}
            onChange={(e) => setTitleToogle2(e.target.value)}
            placeholder={t("enterLinkTitle")}
          />
          <p className="form-label">{t("enterLinkDescription")}</p>
          <input
            type="text"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder={t("enterLinkDescription")}
          />
        </>
      )}

      {toggleOption === 3 && (
        <>
          <p className="form-label">{t("enterContentTitle")}</p>
          <input
            type="text"
            value={titleToogle3}
            onChange={(e) => setTitleToogle3(e.target.value)}
            placeholder={t("enterContentTitle")}
          />
          <p className="form-label">{t("enterContentDescription")}</p>
          <input
            type="text"
            value={descriptionToogle3}
            onChange={(e) => setDescriptionToogle3(e.target.value)}
            placeholder={t("enterContentDescription")}
          />
          <p className="form-label">{t("enterContent")}</p>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder={t("enterContent")}
          />
        </>
      )}

      <p className="form-label">{t("language")}</p>
      <p>
        The episode will be generated in {serieContent.language} and{" "}
        {serieContent && serieContent.additional_languages
          ? serieContent.additional_languages.join(", ") + "."
          : "."}
      </p>

      <p className="form-label">{t("selectVoice")}</p>
      <VoiceSelector
        voices={voices}
        userSubscription={userDetails?.current_subscription}
        selectedVoice={selectedVoice}
        onVoiceChange={handleVoiceChange}
      />

      <p className="form-label" style={{ fontStyle: "italic" }}>
        {t("generationNote")}
      </p>
      <button onClick={searchBeforeGenerate}>{t("generateButton")}</button>

      <audio ref={audioRef} />
    </div>
  );
}

export default Generate;
