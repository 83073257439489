import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Generate.css';
import './GenerateMultiple.css';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable'; // Add CreatableSelect
import { DataContext } from '../DataContext';
import DataTable from 'react-data-table-component';
import { focusIdeas } from './ideas-audio';
import Modal from './Modal'; // Import the Modal component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheckCircle } from '@fortawesome/free-solid-svg-icons'; // Added faCheckCircle for success icon
import { faSpinner } from '@fortawesome/free-solid-svg-icons'; // Spinner icon



const testMode = false;


const sampleData = [
  {
    "id": 0,
      "title": "Milano Basics",
      "desc": "Step-by-step audio guide providing detailed insights and explanations to feel really immerse in the audioguide."
  },
  {
    "id": 1,
      "title": "Milano Moda: The Fashion Capital Chronicles",
      "desc": "Step onto the catwalk of history with 'Milano Moda.' In each episode, we'll explore the fashion houses that made Milan a capital of style, detailing their rise, influence, and the secrets behind their luxurious fabrics. Discover the stories of fashion icons and the trends they set."
  },
  {
      "id": 2,
      "title": "Milan Mystique: Unveiling The Hidden Gems",
      "desc": "Join us as we wander off the beaten path in 'Milan Mystique.' This series guides listeners through Milan's lesser-known locales, revealing secret histories, local legends, and tucked-away trattorias. Perfect for the curious traveler eager to experience Milan's authentic charm."
  },
  {
    "id": 3,
      "title": "Caffeinated in Milan: A Coffee Culture Tour",
      "desc": "\u2018Caffeinated in Milan\u2019 is your audio guide to the art of Italian coffee. Each episode visits a different Milanese caf\u00e9, diving into the rituals, history, and social buzz of Italy\u2019s coffee culture. Learn what makes a perfect espresso and the secrets of baristas."
  },
  // {
  //   "id": 3,
  //     "title": "Milan at the Mic: Sounds of the City",
  //     "desc": "This immersive audio experience, 'Milan at the Mic,' takes listeners on a sonic journey through Milan's vibrant soundscapes. From the buzz of the Navigli to the echoes of the Duomo, each episode is a symphony of the city's auditory treasures."
  // },
  {
    "id": 4,
      "title": "Milan on a Plate: Culinary Tales",
      "desc": "\u2018Milan on a Plate\u2019 serves up the flavors of the city as an audio feast. Discover the stories behind Milanese cuisine, from saffron-infused risotto to the sweet twist of panettone. Each episode pairs local chefs and food experts to dish out Milan\u2019s culinary history."
  },
  {
    "id": 5,
      "title": "Architectural Milano: Foundations of the City",
      "desc": "Delve into 'Architectural Milano' to explore the city's skyline from Roman times to modern marvels. We'll uncover the architects who shaped Milan and their creations that have stood the test of time, influencing the fabric of this metropolitan muse."
  },
  {
    "id": 6,
      "title": "Milan\u2019s Canvas: A Journey Through Art",
      "desc": "Art enthusiasts, 'Milan's Canvas' is your auditory gallery. We'll guide you through Milan\u2019s artistic masterpieces, from Da Vinci's Last Supper to contemporary installations. Learn about the art that adorns the city and the stories of those who painted its history."
  },
  {
    "id": 7,
      "title": "Green Milan: Navigating the Urban Oasis",
      "desc": "\u2018Green Milan\u2019 is an eco-tour of the city's parks and gardens. Each episode blossoms with the sounds of nature and insightful narratives on Milan\u2019s green spaces. Discover the haven of Sempione Park or the tranquil pathways of the Orto Botanico di Brera."
  }
  // ,
  // {
  //   "id": 8,
  //     "title": "Milan Under the Stars: Astronomy and Legends",
  //     "desc": "Gaze up with 'Milan Under the Stars,' a series linking the city's architecture to the cosmos. Learn about Milan's astronomical history, how the stars have influenced its layout, and the myths twinkling above this urban landscape each night."
  // },
  // {
  //   "id": 9,
  //     "title": "Innovative Milan: The Tech and Design Hub",
  //     "desc": "Plug into 'Innovative Milan,' where we delve into the city's forward-thinking spirit in technology and design. Each episode highlights breakthroughs, interviews with innovators, and explores how Milan became a launchpad for cutting-edge ideas and trends."
  // }
];


function GenerateMultipleAudioguides({ getToken }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [cityName, setCityName] = useState("");
  const [focusOptions, setFocusOptions] = useState([]);
  const [customStylesOptions, setCustomStylesOptions] = useState([]); // This is now an array for multiple options
  const [isLoading, setIsLoading] = useState(false);
  const { voices } = useContext(DataContext);
  const [voice, setVoice] = useState("");
  const [generatedIdeas, setGeneratedIdeas] = useState([]);
  const [loadingRowIds, setLoadingRowIds] = useState([]); // Track loading states for each row
  const [showModal, setShowModal] = useState(false);
  const [selectedIdea, setSelectedIdea] = useState(null); // Store the clicked idea

  // Handle focus change (for multiple focus options)
  const handleFocusChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option' && newValue && newValue.label && newValue.label.trim() !== "") {
      // Add the new option only if it contains valid input
      setFocusOptions([...focusOptions, { label: newValue.label, value: newValue.value }]);
    } else if (newValue) {
      setFocusOptions(newValue);
    } else {
      setFocusOptions([]);
    }
  };


  const isRowLoading = (id) => loadingRowIds.includes(id);

  const startLoading = (id) => setLoadingRowIds([...loadingRowIds, id]);

  const stopLoading = (id) => setLoadingRowIds(loadingRowIds.filter(rowId => rowId !== id));

  const generateIdeas = async () => {
    if (testMode) {
      setIsLoading(true); // Set loading state to true to display loading screen
  
      // Wait for 10 seconds before showing the data
      setTimeout(() => {
        setGeneratedIdeas(sampleData); // Set the sample data
        setIsLoading(false); // Set loading state to false to hide the loading screen
      }, 10000);
  
      return;
    }

    if (!cityName) {
      window.alert(t('enterCityName'));
      return;
    }

    const selectedFocus = focusOptions.map(option => option.label).join(', ');

    const prompt = `Generate some title and desc of audioguides series for ${cityName}. Focus on: ${selectedFocus}.`;

    setIsLoading(true);
    try {
      const payload = {
        prompt,
        voice: voices.find(v => v.id === voice),
        language: "en", // Assuming default language is English
        additional_languages: [],
        style: 'audioguides',
        minutes: 5,
      };

      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/series/multiple/ideas`, payload, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json'
        }
      });

      const seriesIdeas = response.data.seriesIdeas.list.map((idea, index) => ({ ...idea, id: index }));
      setGeneratedIdeas(seriesIdeas);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error generating ideas:", error);
      alert(t('errorGeneratingIdeas'));
    }
  };

  const generateSingleSeries = async (idea) => {
    startLoading(idea.id); // Start loading
  
    try {
      const payload = {
        title: idea.title,
        desc: idea.desc,
        voice: voices.find(v => v.id === voice),
        language: "en",
        additional_languages: [],
        style: 'audioguides',
        minutes: 5,
        accessToken: await getToken(),
      };
  
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/series/generate`, payload, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json'
        }
      });
  
      setGeneratedIdeas(generatedIdeas.map(i => i.id === idea.id ? { ...i, success: true } : i));
      stopLoading(idea.id); // Stop loading after success
    } catch (error) {
      stopLoading(idea.id); // Stop loading on error
      console.error("Error generating single series:", error);
      alert(t('errorGeneratingSingleSeries'));
    }
  };
  

  const deleteIdea = (id) => {
    setGeneratedIdeas(generatedIdeas.filter(idea => idea.id !== id));
  };

  const generateAllSeries = async () => {
    for (const idea of generatedIdeas) {
      if (!idea.success) { // Only generate series for rows that haven't been successful
        await generateSingleSeries(idea);
      }
    }
  };

  
  const columns = [
    {
      name: t('title'),
      selector: row => row.title,
      sortable: true,
      width: '40%',
      cell: row => (
        <div className="table-tooltip">
          <FontAwesomeIcon 
            icon={isRowLoading(row.id) ? faSpinner : row.success ? faCheckCircle : null} 
            spin={isRowLoading(row.id)} 
            style={{ marginRight: '8px', color: row.success ? 'green' : '' }}
          />
          <span>{row.title.length > 30 ? `${row.title.substring(0, 30)}...` : row.title}</span>
        </div>
      ),
    },
    {
      name: t('description'),
      selector: row => row.desc,
      sortable: true,
      width: '40%',
      cell: row => (
        <div className="table-tooltip" onClick={() => handleRowClick(row)}>
          <span>{row.desc.length > 50 ? `${row.desc.substring(0, 50)}...` : row.desc}</span>
        </div>
      ),
    },
    {
      name: t('actions'),
      width: '10%',
      cell: row => (
        <button
          style={{ width: "100%" }}
          onClick={() => deleteIdea(row.id)}
          disabled={isRowLoading(row.id)}
        >
          {isRowLoading(row.id) ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <FontAwesomeIcon icon={faTrash} />
          )}
        </button>
      ),
    },
  ];

  const handleRowClick = (row) => {
    setSelectedIdea(row);
    setShowModal(true); // Show modal on row click
  };

  useEffect(() => {
    if (voices.length && !voice) {
      setVoice(voices[0].id);
    }
  }, [voices, voice]);

  const closeModal = () => {
    setShowModal(false);
    setSelectedIdea(null);
  };

  return (
    <div className="content-package" id="generator">
      <h1 id="baseSelectorTitle">{t('createMultipleSeriesTitle')}</h1>

      {isLoading ? (
      <div className="loading-screen">
        <FontAwesomeIcon icon={faSpinner} spin /> {/* Add a loading spinner */}
        <p>Loading, please wait...</p>
      </div>
    ) : (
      <>
      {generatedIdeas.length === 0 && (
        <>
          <p className="form-label">{t('enterCityName')}</p>
          <input
            type="text"
            value={cityName}
            onChange={(e) => setCityName(e.target.value)}
            placeholder={t('enterCityNamePlaceholder')}
          />

          <p className="form-label">{t('selectFocus')}</p>
          <CreatableSelect
            isMulti
            options={focusIdeas}
            onChange={handleFocusChange}
            value={focusOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            closeMenuOnSelect={false} // Keep dropdown open after selection
          />

          <p className="form-label">{t('selectVoice')}</p>
          <select value={voice} onChange={(e) => setVoice(e.target.value)}>
            {voices.map((v) => (
              <option key={v.id} value={v.id}>
                {v.name} - {v.labels.accent}, {v.labels.age}, {v.labels.gender}, {v.labels.usecase}
                {v.premium ? " (premium)" : ""}
              </option>
            ))}
          </select>

          <button onClick={generateIdeas}>{t('generateIdeasButton')}</button>
        </>
      )}

      {generatedIdeas.length > 0 && (
        <>
          <div style={{ width: '90%' }}>
            <DataTable
              columns={columns}
              data={generatedIdeas}
              pagination
              highlightOnHover
              noHeader
              onRowClicked={handleRowClick}
            />
          </div>

          <button onClick={generateAllSeries} style={{width:"40%"}}>{t('submitAllSeriesButton')}</button>
          </>
      )}
     

      {selectedIdea && (
        <Modal
          show={showModal}
          onClose={closeModal}
          title={selectedIdea.title}
          description={selectedIdea.desc}
          deleteMethod={() => {deleteIdea(selectedIdea.id), closeModal()}}
          actionMethod={() => generateSingleSeries(selectedIdea)}
          actionName={t('generateOnly')}
        />
      )}
       </>)}
    </div>
  );
}

export default GenerateMultipleAudioguides;
