export const focusIdeas = [
    { value: 'basicCity', label: 'Basic City Overview' },
    { value: 'individualmonuments', label: 'All the main monuments individually' },
    { value: 'monuments', label: 'Focus on the Monuments' },
    { value: 'middleAges', label: 'Middle Age Activities' },
    { value: 'artisticVibe', label: 'Artistic Vibe' },
    { value: 'culinaryTour', label: 'Culinary Tour' },
    { value: 'greenSpaces', label: 'Green Spaces & Parks' },
    { value: 'modernArchitecture', label: 'Modern Architecture' },
    { value: 'localLegends', label: 'Local Legends and Myths' },
    { value: 'historicalFigures', label: 'Historical Figures' },
    { value: 'streetArt', label: 'Street Art and Murals' },
    { value: 'nightlife', label: 'Nightlife and Entertainment' },
    { value: 'musicScene', label: 'Music Scene' },
    { value: 'literaryConnections', label: 'Literary Connections' },
    { value: 'royaltyAndPalaces', label: 'Royalty and Palaces' },
    { value: 'religiousSites', label: 'Religious Sites' },
    { value: 'scienceAndInnovation', label: 'Science and Innovation' },
    { value: 'industrialHistory', label: 'Industrial History' },
    { value: 'fashionAndDesign', label: 'Fashion and Design' },
    { value: 'secretsAndHiddenGems', label: 'Secrets and Hidden Gems' },
    { value: 'militaryHistory', label: 'Military History' },
    { value: 'maritimeHistory', label: 'Maritime History' },
    { value: 'urbanMyths', label: 'Urban Myths and Folklore' },
    { value: 'localCuisine', label: 'Local Cuisine Specialties' },
    { value: 'marketsAndShopping', label: 'Markets and Shopping Hotspots' },
    { value: 'ecoFriendlySpots', label: 'Eco-Friendly and Sustainable Spots' },
    { value: 'cityFestivals', label: 'City Festivals and Events' },
    { value: 'romanticSpots', label: 'Romantic Spots' },
    { value: 'architectureThroughTheAges', label: 'Architecture Through the Ages' },
    { value: 'culturalInstitutions', label: 'Cultural Institutions' },
    { value: 'artGalleries', label: 'Art Galleries and Museums' },
    { value: 'famousFilmLocations', label: 'Famous Film Locations' },
    { value: 'unusualLandmarks', label: 'Unusual and Quirky Landmarks' },
    { value: 'sportsHistory', label: 'Sports History' },
    { value: 'riversAndCanals', label: 'Rivers and Canals' },
    { value: 'oldTownWalks', label: 'Old Town Walks' },
    { value: 'cityThroughTheSeasons', label: 'City Through the Seasons' },
    { value: 'famousResidents', label: 'Famous Residents of the City' },
    { value: 'bridgesAndCrossings', label: 'Bridges and Crossings' },
    { value: 'urbanPlanning', label: 'Urban Planning and Development' },
    { value: 'transportationHistory', label: 'Transportation and Railways' },
    { value: 'revolutionaryMovements', label: 'Revolutionary Movements' },
    { value: 'cityInLiterature', label: 'City in Literature' },
    { value: 'historicInnsAndTaverns', label: 'Historic Inns and Taverns' },
    { value: 'gardensAndBotanicalSpaces', label: 'Gardens and Botanical Spaces' },
    { value: 'wildlifeInTheCity', label: 'Wildlife in the City' },
    { value: 'royalConnections', label: 'Royal Connections and Heritage' },
    { value: 'marketsAndBazaars', label: 'Markets and Bazaars' },
    { value: 'famousBattles', label: 'Famous Battles and War Sites' },
    { value: 'educationalInstitutes', label: 'Educational Institutes and Universities' },
    { value: 'politicalLandmarks', label: 'Political Landmarks' }
  ];
  