import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Generate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneLines, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { pushGTMEvent } from '../GoogleAnalytics';
import { DataContext } from '../DataContext';
import { useTranslation } from 'react-i18next';
import Select from 'react-select'; // ensure react-select is installed

import { languageOptions } from './LanguageOptions';

function GenerateSeries({ getToken }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [language, setLanguage] = useState("en");
  const [additionalLanguages, setAdditionalLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { voices } = useContext(DataContext);
  
  const [audioStyle, setAudioStyle] = useState("podcasts");
  const [voice, setVoice] = useState("");
  const [numOfMinutes, setNumOfMinutes] = useState(5);
  const [generateImgIndividually, setGenerateImgIndividually] = useState(false); // New state for toggle
  const [generateImg, setGenerateImg] = useState(true);
  const [uploadedImage, setUploadedImage] = useState(null);

  const styleDescriptions = {
    audiobooks: "Audio version of the book in the title focusing on the facts and the ideas of the author. Keeping its writing style in the audio speech.",
    podcasts: "Interesting, fun and full of facts podcast.",
    lectures: "Educational and informative delivery of the content in a lecture style.",
    storytelling: "Narrative-driven and engaging retelling of a book's story with facts and explanations, or creation of a full on story.",
    audioguides: "Step-by-step audio guide providing detailed insights and explanations to feel really immerse in the audioguide."
  };

  console.log("generate full complete series")

  useEffect(() => {
    if (voices.length && !voice) {
      setVoice(voices[0].id);
    }
  }, [voices, voice]);

  useEffect(() => {
    
    if (!description) {
      setDescription(styleDescriptions[audioStyle]);
    } else {
      for (let key in styleDescriptions) {
        if (styleDescriptions[key] === description) {
          setDescription(styleDescriptions[audioStyle]);
          break;
        }
      }
    }
    
  }, [audioStyle]);


  const additionalLanguageOptions = languageOptions.filter(option => option.value !== language);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedImage(reader.result); // this is a base64 encoded string
    };
    reader.readAsDataURL(file);
  };

  const createSeries = async () => {
    if (!description || !name || (!generateImg && !uploadedImage)) {
      window.alert(t('fillAllFields'));
      return;
    }
    setIsLoading(true);
    try {
      const payload = {
        title: name,
        desc: description,
        language: language,
        additional_languages: additionalLanguages.length ? additionalLanguages.map(lang => lang.value) : [],
        accessToken: await getToken(),
        style: audioStyle,
        voice: voices.find(v => v.id === voice),
        minutes: numOfMinutes,
        individual_images: generateImgIndividually,
        img: !generateImg ? uploadedImage : undefined
      };

      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/series/generate`, payload, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json'
        }
      });
      setIsLoading(false);
      navigate('/app/content/serie/' + response.data.object[0]._id);
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating series:", error);
      alert(t('errorCreatingSeries'));
    }
  };

  if (isLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
        <div className="loading-text">{t('loading')}</div>
      </div>
    );
  }

  return (
    <div className="content-package" id="generator">
      <h1 id="baseSelectorTitle">{t('createSeriesTitle')}</h1>

      <p className="form-label">{t('selectStyle')}</p>
      <select value={audioStyle || ''} onChange={(e) => setAudioStyle(e.target.value)}>
        <option value="podcasts">{t('podcasts')}</option>
        <option value="audiobooks">{t('audiobooks')}</option>
        <option value="lectures">{t('lectures')}</option>
        <option value="storytelling">{t('storytelling')}</option>
        <option value="audioguides">{t('audioguides')}</option>
      </select>

      <p className="form-label">{t('enterSeriesName')}</p>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder={t('enterSeriesNamePlaceholder')}
      />

      <p className="form-label">{t('describeSeries')}</p>
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder={t('enterSeriesDescriptionPlaceholder')}
      />

      <p className="form-label">{t('selectLanguage')}</p>
      <select value={language} onChange={(e) => setLanguage(e.target.value)}>
        {languageOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>

      <p className="form-label">{t('selectAdditionalLanguages')}</p>
      <Select
        isMulti
        options={additionalLanguageOptions}
        onChange={setAdditionalLanguages}
        className="basic-multi-select"
        classNamePrefix="select"
      />

      <p className="form-label">{t('generateImg')}</p>
      <label className="form-generation-switch">
        <input
          type="checkbox"
          checked={generateImg}
          onChange={(e) => {
            setGenerateImg(e.target.checked);
            setGenerateImgIndividually(false); // Ensure this is reset
            setUploadedImage(null); // Clear previous image
          }}
        />
        <span className="form-generation-slider"></span>
      </label>

      {!generateImg && (
        <>
          <p className="form-label">{t('uploadImage')}</p>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
          />
        </>
      )}

      {/* {generateImg && (
        <>
          <p className="form-label">{t('generateImgIndividually')}</p>
          <label className="form-generation-switch">
            <input
              type="checkbox"
              checked={generateImgIndividually}
              onChange={() => setGenerateImgIndividually(!generateImgIndividually)}
            />
            <span className="form-generation-slider"></span>
          </label>
        </>
      )} */}

      <span style={{ height: "20px", width: "70%" }}></span>

      {/* <p className="form-label">{t('selectLength')}</p>
      <select value={numOfMinutes || 5} onChange={(e) => setNumOfMinutes(e.target.value)}>
        <option value="5">5 minutes</option>
        <option value="10">10 minutes</option>
      </select> */}

      <p className="form-label">{t('selectVoice')}</p>
      <select value={voice} onChange={(e) => setVoice(e.target.value)}>
        {voices.map((v) => (
          <option key={v.id} value={v.id}>
            {v.name} - {v.labels.accent}, {v.labels.age}, {v.labels.gender}, {v.labels.usecase}
            {v.premium ? " (premium)" : ""}
          </option>
        ))}
      </select>

      <button onClick={createSeries}>{t('createSeriesButton')}</button>
    </div>
  );
}

export default GenerateSeries;
