import React from 'react';
import './Modal.css';

const Modal = ({ show, onClose, title, description, deleteMethod, actionMethod, actionName }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="modal-close" onClick={onClose}>&times;</span>
        <h2>{title}</h2>
        <p>{description}</p>
        
        <div className="modal-buttons">
          {deleteMethod && (
            <button className="delete-button" onClick={deleteMethod}>
              Delete
            </button>
          )}

          {actionMethod && actionName && (
            <button className="action-button" onClick={actionMethod}>
              {actionName}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
