import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { fetchSerie } from "./Fetching";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faRocket,
  faShareAlt,
  faTrash,
  faRss,
} from "@fortawesome/free-solid-svg-icons";
import "./SeriePage.css"; // Assuming you have a corresponding CSS file
import ButtonPlayEpisode from "./ButtonPlayEpisode";
import { pushGTMEvent } from "../GoogleAnalytics";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";
import QRCodeGenerator from "./QrCode";
import { languageOptions } from "./LanguageOptions";

function SeriePage({
  isLoadingEpisode,
  isPlaying,
  episodePlaying,
  handlePlay,
  isGenerating,
  handlePause,
  getToken,
}) {
  const { t, i18n } = useTranslation();
  const { isAuthenticated, user, logout } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();
  const [series, setSeries] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(
    i18n.language.substring(0, 2)
  ); // Default to English
  const baseUrl = window.location.origin;
  const sharePath = `${baseUrl}/#/app/public/content/${id}`;

  useEffect(() => {
    pushGTMEvent("page_view", t("pageInteraction"), location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const loadSerie = async () => {
      const token = await getToken();
      try {
        const data = await fetchSerie(id, token, selectedLanguage); // Pass selected language to fetchSerie
        if (!data && !data.id) {
          navigate("/#/app"); // Redirect if no series data
        } else {
          data["additional_languages"] = data["additional_languages"] || [];
          data["additional_languages"].push(data["language"]);
          setSeries(data);
        }
      } catch (error) {
        console.error("Error fetching series:", error);
        navigate("/#/app"); // Redirect on error
      } finally {
        setLoading(false);
      }
    };

    loadSerie();
  }, [id, selectedLanguage, getToken, navigate]); // Refetch when id or selectedLanguage changes

  const handleShare = async () => {
    pushGTMEvent("feedback", t("share"), id);

    if (navigator.share) {
      try {
        await navigator.share({
          title: series.title,
          text: t("checkOutSeries"),
          url: window.location.href,
        });
        console.log("Series shared successfully");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      try {
        await navigator.clipboard.writeText(window.location.href);
        alert(t("urlCopied"));
      } catch (error) {
        console.error("Failed to copy URL:", error);
        alert(t("urlCopyFail"));
      }
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(t("confirmDeleteSeries"));
    if (!confirmDelete) return;

    var token = await getToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/series/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.success) {
        alert(t("deleteSuccess"));
        navigate("/app");
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error("Error deleting series:", error);
      alert(t("deleteFailed"));
    }
  };

  const getRssFeedUrl = () => {
    return `${process.env.REACT_APP_XML_ENDPOINT}${id}_${selectedLanguage}_feed.xml`;
  };

  const handleCopyRssFeed = async () => {
    const rssFeedUrl = getRssFeedUrl();
    try {
      await navigator.clipboard.writeText(rssFeedUrl);
      alert(t("rssCopied"));
    } catch (error) {
      console.error("Failed to copy RSS feed URL:", error);
      alert(t("rssCopyFail"));
    }
  };

  if (loading) {
    return <Loading />;
  }

  const goToEpisode = (episodeId) => {
    pushGTMEvent("episode_view", t("episodeView"), episodeId);
    navigate(`/app/content/episode/${episodeId}`);
  };

  const goToGenerateEpisode = () => {
    pushGTMEvent("generate_episode", t("episodeGenerate"), id);
    navigate(`/app/generate/episode/${id}`);
  };

  const handleLanguageChange = (e) => {
    setLoading(true);
    setSelectedLanguage(e.target.value);
  };

  return (
    <div className="serie-page">
      <div className="serie-header">
        <img src={series.imageUrl} alt={series.title} className="serie-image" />
        <div className="serie-details">
          <h1>{series.title}</h1>
          <p>{series.desc}</p>
          {series.additional_languages &&
            series.additional_languages.length > 1 && (
              <select
                className="language-select"
                value={selectedLanguage}
                onChange={handleLanguageChange}
                style={{ width: "150px", margin: "10px", padding: "5px" }}
              >
                {series.additional_languages.map((lang) => (
                  <option key={lang} value={lang}>
                    {
                      languageOptions.find((option) => option.value === lang)
                        .label
                    }
                  </option>
                ))}
              </select>
            )}
          {series.creator_id === user.sub && (
            <button
              className="play-button-episode-list"
              style={{
                backgroundColor: "#89023E",
                marginRight: "5px",
                marginBottom: "10px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                goToGenerateEpisode();
              }}
            >
              <FontAwesomeIcon icon={faRocket} /> {t("generateEpisode")}
            </button>
          )}

          {series.creator_id === user.sub && (
            <>
              <button onClick={handleDelete} className="delete-button">
                <FontAwesomeIcon icon={faTrash} /> {t("delete")}
              </button>
              <button
                onClick={handleCopyRssFeed}
                className="share-button play-button-episode-list"
                style={{
                  margin: "10px",
                }}
              >
                <FontAwesomeIcon icon={faRss} /> {t("copyRssFeed")}
              </button>
            </>
          )}
          <button
            onClick={handleShare}
            className="share-button play-button-episode-list"
            style={{ marginRight: "20px", marginBottom: "10px" }}
          >
            <FontAwesomeIcon icon={faShareAlt} /> {t("share")}
          </button>

          {series.episodes.length > 0 && (
            <ButtonPlayEpisode
              id={series.episodes[0]._id}
              isPlaying={isPlaying}
              isGenerating={isGenerating}
              isLoadingEpisode={isLoadingEpisode}
              episodePlaying={episodePlaying}
              handlePlay={handlePlay}
              handlePause={handlePause}
            />
          )}
          {series.creator_id === user.sub && (
            <QRCodeGenerator
              btnTitle={t("getPublicAccess")}
              linkProvided={sharePath}
            />
          )}
        </div>
      </div>

      <div className="episode-list">
        <h2>{t("episodes")}</h2>
        {series.episodes.map((episode) => (
          <div
            key={episode._id}
            className="episode-item"
            onClick={() => goToEpisode(episode._id)}
          >
            <img
              src={episode.imageUrl}
              alt={episode.title}
              className="episode-img"
            />
            <div className="episode-info">
              <h3>{episode.title}</h3>
              <p title={episode.desc}>
                {episode.desc.length > 250
                  ? `${episode.desc.substring(0, 250)}...`
                  : episode.desc}
              </p>
            </div>
            <ButtonPlayEpisode
              id={episode._id}
              isPlaying={isPlaying}
              isLoadingEpisode={isLoadingEpisode}
              episodePlaying={episodePlaying}
              isGenerating={isGenerating}
              handlePlay={handlePlay}
              handlePause={handlePause}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SeriePage;
